import apiService from './index';

const ORDER_URL = '/giveaway/rt/orders/';

export default {
  orders: {
    async list(params) {
      const response = await apiService.get(ORDER_URL, { params });
      return response;
    },
  },
};

<template>
  <div class="container">
    <div class="hovering__pad p-4">
      <h2>Статистика по гивам</h2>
      <span style="color: gray"> Выданные промокоды</span>
      <TimeTable :rawData="gives" />
    </div>
  </div>
</template>

<script>
import giveaway from '@main/api/giveaway';
import TimeTable from './TimeTable.vue';

export default {
  components: {
    TimeTable,
  },
  data: () => ({
    gives: [],
  }),
  async mounted() {
    const res = await giveaway.orders.list({ limit: 30000, fields: 'code_issued' });
    this.gives = res.results;
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <b-table :busy="!table[0].Количество" :items="table" v-bind="{ ...$attrs, ...$props }">
    <template #table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
      </div>
    </template>
  </b-table>
</template>

<script>
import * as moment from 'moment';
import 'moment/locale/ru';

/* eslint-disable no-plusplus, vue/no-side-effects-in-computed-properties */
export default {
  props: {
    rawData: Array,
  },
  data: () => ({
    months: 12,
    date: null,
    loading: true,
  }),
  computed: {
    table() {
      const data = this.lodash.cloneDeep(this.rawData);
      const date = moment();
      date.subtract(this.months, 'M');
      date.startOf('month');
      const year = [];
      for (let month = 1; month <= 14; month++) {
        const monthObj = data.filter((val) => {
          const end = date.clone();
          end.endOf('month');
          return moment(val.code_issued).isBetween(date, end);
        });
        monthObj.forEach((val) => {
          const index = data.indexOf(val);
          data.splice(index, 1);
        });
        year.push({
          Год: date.format('YYYY'),
          Месяц: date.format('MMMM').replace(/\b\S/g, (t) => t.toUpperCase()),
          Количество: monthObj.length,
        });
        date.add(1, 'M');
      }
      return year;
    },
  },
  mounted() {
    console.log(this.table);
  },
};
</script>
